@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.inputField {
  display: block;
  width: 100%;
  margin: auto;
}
.label {
  @include mix.label;
  color: colors.$dark-black;
}
.required {
  @include mix.label;
  color: colors.$orange;
  margin-left: 8px;
}
.input {
  @include mix.body();
  width: 100%;
  border: 1px solid #bbbbbb;
  background: colors.$gray;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 8px;
}
