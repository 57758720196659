@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.section {
  @include mix.small-and-down {
    height: 100%;
    padding: 0 24px 64px;
  }
  @include mix.medium-and-up {
    padding: 0 40px 64px;
    border-radius: 12px;
  }
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: colors.$white;
}
.title {
  @include mix.heading-l;
  color: colors.$text-color-black;
  text-align: center;
}
.subtitle {
  @include mix.heading-m;
  color: colors.$text-color-black;
  text-align: center;
  white-space: pre-wrap;
  margin-top: 0;
}
.contents {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
}
