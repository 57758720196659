@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.form {
  width: 100%;
  max-width: 640px;
  margin: auto;
}
.item {
  &:not(:first-of-type) {
    margin-top: 24px;
  }
}
.submit {
  margin-top: 40px;
  text-align: center;
}
.error {
  margin-top: 40px;
}
.text {
  @include mix.body;
  text-align: center;
  margin-top: 24px;
  color: colors.$green;
}
