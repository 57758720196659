@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.caution {
  display: flex;
  align-items: center;
  border: 1px solid #ffa400;
  border-radius: 8px;
  padding: 8px 16px;
  background: #fff4df;

  span {
    @include mix.body;
    color: colors.$text-color-black;
    margin-left: 16px;
  }
}
