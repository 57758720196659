@mixin set-typography($size: 16px, $weight: 400, $line-height: 1.7, $letter-spacing: 0.04em) {
  $font-family: "Noto Sans", "Noto Sans JP", sans-serif;
  font: $weight $size $font-family;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin small-and-down {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin medium-and-up {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin heading-l {
  margin: 64px 0 24px 0;
  @include small-and-down() {
    @include set-typography(32px, 500, 1.5);
  }
  @include medium-and-up() {
    @include set-typography(36px, 400, 1.4);
  }
}

@mixin heading-m {
  margin: 64px 0 24px 0;
  @include small-and-down() {
    @include set-typography(28px, 500, 1.5);
  }
  @include medium-and-up() {
    @include set-typography(32px, 400, 1.5);
  }
}

@mixin heading-s {
  margin: 40px 0 24px 0;
  @include small-and-down() {
    @include set-typography(24px, 500, 1.5);
  }
  @include medium-and-up() {
    @include set-typography(28px, 400, 1.5);
  }
}

@mixin heading-xs {
  margin: 40px 0 16px 0;
  @include small-and-down() {
    @include set-typography(20px, 500, 1.5);
  }
  @include medium-and-up() {
    @include set-typography(24px, 400, 1.5);
  }
}

@mixin heading-xxs {
  margin: 40px 0 16px 0;
  @include small-and-down() {
    @include set-typography(16px, 500, 1.7);
  }
  @include medium-and-up() {
    @include set-typography(20px, 400, 1.5);
  }
}

@mixin body {
  @include set-typography();
}

@mixin label {
  @include set-typography(14px, 500, 1.5);
}

@mixin caption {
  @include set-typography(12px, 400, 1.7, 0.02em);
}

@mixin button {
  @include set-typography(16px, 700, 1.5);
}

@mixin bpOnlySp {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin bpOverTab {
  @media screen and (min-width: 768px) {
    @content;
  }
}
