@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email {
  @include mix.heading-m;
  color: #0094ff;
  margin: 0 auto 24px;
}
.body {
  @include mix.body;
  margin: 0 auto;
}
.actions {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 40px;
}
.case {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
}
.button {
  display: flex;
  width: 100%;
  max-width: 640px;
  margin: 24px auto;
}
.caution {
  @include mix.body;
  color: colors.$text-color-black;
  margin: 24px auto;
}
