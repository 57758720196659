@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.button {
  @include mix.button;
  display: inline-block;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  padding: 16px;
  border: 2px solid colors.$green;
  border-radius: 8px;
  transition: all 0.2s ease-out;

  &--outline {
    color: colors.$green;
    background: colors.$white;
    &:hover {
      background: darken($color: colors.$white, $amount: 5);
    }
    &:active {
      background: darken($color: colors.$white, $amount: 13);
    }
  }
  &--contained {
    color: colors.$white;
    background: colors.$green;
    &:hover {
      background: darken($color: colors.$green, $amount: 5);
    }
    &:active {
      background: darken($color: colors.$green, $amount: 13);
    }
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
    border-color: #ccc;
    &:hover {
      background: #ccc;
      border-color: #ccc;
    }
  }
}
