@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.container {
  @include mix.body;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: colors.$text-color-black;
  background: #f9f9f9;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  text-align: center;
  @include mix.small-and-down {
    padding: 16px;
  }
  @include mix.medium-and-up {
    padding: 24px;
  }
}
.heading {
  @include mix.heading-xs;
  margin-top: 0;
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.price {
  @include mix.heading-xxs;
  white-space: nowrap;
  margin-top: 0;
}
.value {
  margin-right: 8px;
  margin-left: 8px;
}
.button {
  @include mix.button;
  margin-top: 24px;
}
