@use "assets/scss/_colors" as colors;
@use "assets/scss/_mixins" as mix;

.form {
  width: 100%;
  max-width: 640px;
  margin: auto;
}
.item {
  &:not(:first-of-type) {
    margin-top: 24px;
  }
}
.submit {
  margin-top: 40px;
  text-align: center;
}
.error {
  margin-top: 40px;
}
.caution {
  @include mix.caption;
  color: colors.$text-color-black;
  margin-top: 8px;
}
.checkbox {
  @include mix.body;
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    color: colors.$text-color-black;
    margin-left: 16px;
  }
  a {
    color: colors.$green;
    text-decoration: underline;
  }
  input {
    cursor: pointer;
  }
}
.text {
  @include mix.body;
  text-align: center;
  margin-top: 24px;
  color: colors.$green;
}
