@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.wrapper {
  width: 100%;
  background: colors.$gray;
  @include mix.medium-and-up {
    padding: 64px 40px;
  }
}
