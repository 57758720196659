@use "assets/scss/_mixins" as mix;
@use "assets/scss/_colors" as colors;

.plans {
  display: flex;
  @include mix.small-and-down() {
    flex-direction: column;
  }
  @include mix.medium-and-up() {
    justify-content: center;
  }
}
.card {
  width: 100%;
  &:not(:first-of-type) {
    @include mix.small-and-down() {
      margin-top: 32px;
    }
    @include mix.medium-and-up() {
      margin-left: 32px;
    }
  }
}
